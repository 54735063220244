import React from 'react';
import Img from 'gatsby-image';

export default function ContactBanner({ data, pageContext }) {
  return (
    <div className="section-banner contact">
      <Img className={'hero-image'} fluid={data.image.asset.fluid} loading={"eager"} alt="" fadeIn={false}/>
      <div className="overlay contact"></div>
      <div className="section-banner-text">
        <span className="h5">{data.subtitle[pageContext]}</span>
        <h1>{data.title[pageContext]}</h1>
      </div>
    </div>
  );
}
