import React from 'react';
import { graphql } from 'gatsby';
import ContactBanner from "../sections/contactPage/ContactBanner";
import { ContactPageSection } from '../styles/ContactStyles';
import ContactForm from "../sections/contactPage/ContactForm";
import Footer from "../components/Footer";

export default function ContactPage({ data, pageContext }) {
  return (
    <>
      <ContactPageSection>
        <ContactBanner data={data.sanityContactBanner} pageContext={pageContext.locale} />
        <ContactForm id="contactPage" data={data.sanityContactForm} pageContext={pageContext.locale} grid={'column'} />
        <Footer data={data.sanityFooter} pageContext={pageContext.locale}/>
      </ContactPageSection>
    </>
  );
}

export const query = graphql`
  query ContactQuery {
    sanityContactBanner {
      title {
        en
        fr
      }
      subtitle {
        en
        fr
      }
      image {
        asset {
          fluid(sizes: "(max-width: 2400px) 100vw, 2400px") {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityContactForm {
      title {
        en
        fr
      }
      description {
          en
          fr
      }
      regulations {
        en {
          _rawChildren
        }
        fr {
          _rawChildren
        }
      }
    }
    sanityFooter {
      phone
      email
      address_line1
      address_line2
      social_media_list {
        website
        link
      }
      footer_nav_list {
        link
        title {
          en
          fr
        }
      }
    }
  }
`;